@import "./prism.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Main background color used for the page/app background */
    --background: 0 0% 100%;
    /* Main text color used for most text content */
    --foreground: 240 10% 3.9%;
    /* Background color for card components */
    --card: 0 0% 100%;
    /* Text color used within card components */
    --card-foreground: 240 10% 3.9%;
    /* Background color for popovers, dropdowns, and floating menus */
    --popover: 0 0% 100%;
    /* Text color used within popovers */
    --popover-foreground: 240 10% 3.9%;
    /* Primary brand color used for main actions, buttons, and links */
    --primary: 240 5.9% 10%;
    /* Text color used on primary colored backgrounds */
    --primary-foreground: 0 0% 98%;
    /* Secondary color used for less prominent actions and backgrounds */
    --secondary: 240 4.8% 95.9%;
    /* Text color used on secondary colored backgrounds */
    --secondary-foreground: 240 5.9% 10%;
    /* Color used for subtle backgrounds and disabled states */
    --muted: 240 4.8% 35%; /* Less light gray */
    /* Text color used for helper text and less important content */
    --muted-foreground: 240 3.8% 46.1%;
    /* Used for hover states and highlighted elements */
    --accent: 240 4.8% 95.9%;
    /* Text color used on accent colored backgrounds */
    --accent-foreground: 240 5.9% 10%;
    /* Color used for error states and destructive actions */
    --destructive: 0 72.22% 50.59%;
    /* Text color used on destructive colored backgrounds */
    --destructive-foreground: 0 0% 98%;
    /* Color used for borders and dividers */
    --border: 240 5.9% 90%;
    /* Color used for form input borders and outlines */
    --input: 240 5.9% 90%;
    /* Color used for focus rings and selection highlights */
    --ring: 240 5% 64.9%;
    /* Default border radius used throughout the app */
    --radius: 0.5rem;
    /* Colors used for charts and data visualization */
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    /* Sidebar specific colors */
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    /* --background: 240 5% 8.9%; */
    --background: 216, 71%, 1%;
    --foreground: 0 0% 98%;
    /* Card background color: #18181B (rgb(24, 24, 27)) */
    --card: 240 6% 10%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    /* --muted: 240 3.7% 15.9%; */
    --muted: 240 3.7% 75%; /* Less dark gray */
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  * {
    @apply border-border;
  }
  html {
    @apply scroll-smooth;
  }
  body {
    @apply overscroll-none bg-background text-foreground;
    /* font-feature-settings: "rlig" 1, "calt" 1; */
    font-synthesis-weight: none;
    text-rendering: optimizeLegibility;
  }

  @supports (font: -apple-system-body) and (-webkit-appearance: none) {
    [data-wrapper] {
      @apply min-[1800px]:border-t;
    }
  }

  /* Custom scrollbar styling. Thanks @pranathiperii. */
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: hsl(var(--border));
    border-radius: 5px;
  }
  * {
    scrollbar-width: thin;
    scrollbar-color: hsl(var(--border)) transparent;
  }
}

@layer components {
  .gradient-heading {
    background: linear-gradient(to right, #1a1a1a, #434343);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    position: relative;
  }

  :is(.dark .gradient-heading) {
    background: linear-gradient(to right, #ffffff, #e0e0e0);
    -webkit-background-clip: text;
    background-clip: text;
  }
}

@layer utilities {
  .card-wrapper {
    @apply relative overflow-hidden rounded-2xl bg-gradient-to-b from-slate-700 to-slate-800;
  }

  /* Gradient */
  .card-wrapper::before {
    background: conic-gradient(
      rgba(244, 114, 182, 0.4) 0deg,
      rgba(192, 132, 252, 0.4) 0deg,
      transparent 80deg
    );

    @apply absolute left-[-25%] top-[-25%] h-[150%] w-[150%] animate-border-spin content-[''];
  }

  /* Body */
  .card-content {
    @apply absolute left-[1px] top-[1px] h-[calc(100%-2px)] w-[calc(100%-2px)] rounded-2xl bg-gradient-to-b from-slate-800 to-slate-900;
  }

  gradient {
    border: 1px solid transparent;
    background:
      linear-gradient(#0f172a, #0f172a) padding-box,
      conic-gradient(
          from 0deg,
          rgb(148 163 184),
          rgb(51 65 85) 25%,
          rgb(51 65 85) 75%,
          rgb(148 163 184) 100%
        )
        border-box;
  }

  .step {
    counter-increment: step;
  }

  .step:before {
    @apply absolute inline-flex h-9 w-9 items-center justify-center rounded-full border-4 border-background bg-muted text-center -indent-px font-mono text-base font-medium;
    @apply ml-[-50px] mt-[-4px];
    content: counter(step);
  }

  .chunk-container {
    @apply shadow-none;
  }

  .chunk-container::after {
    content: "";
    @apply absolute -inset-4 rounded-xl border shadow-xl;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .border-grid {
    @apply border-border/30 dark:border-border;
  }

  .container-wrapper {
    @apply mx-auto w-full border-border/30 dark:border-border min-[1800px]:max-w-[1536px] min-[1800px]:border-x;
  }

  .container {
    @apply mx-auto max-w-[1536px] px-4 xl:px-6 2xl:px-4;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.radial {
  background-image: radial-gradient(
    ellipse farthest-side,
    white,
    #716768,
    black
  );
  background-size: 100% 90%;
  background-repeat: no-repeat;
}

.text-gradient {
  background: linear-gradient(to right, #4a4e58, white, #716768);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-radial {
  background-image: radial-gradient(
    ellipse farthest-side,
    rgba(113, 103, 104, 0.2) 30%,
    black 70%
  );
  background-size: 100% 50%;
  background-repeat: no-repeat;
}

.text-radial--circle {
  background-image: radial-gradient(
    circle farthest-side,
    rgba(113, 103, 104, 0.35) 20%,
    black 70%
  );
  background-size: 50% 70%;
  background-repeat: no-repeat;
  background-position: top center;
}

.backdrop--blur__safari {
  -webkit-backdrop-filter: blur(5px);
}

.radial--blur {
  -webkit-backdrop-filter: blur(100px);
  filter: blur(100px);
  background: #877874;
}

@media only screen and (max-width: 800px) {
  .text-radial--circle {
    background-image: radial-gradient(
      circle farthest-side,
      rgba(113, 103, 104, 0.2) 20%,
      black 70%
    );
    background-size: 100% 50%;
    background-repeat: no-repeat;
    background-position: top center;
  }
}

.swiper-autoheight .swiper-slide {
  height: 100% !important;
}

.content-width-slide {
  width: fit-content !important;
}

.img--overlay {
  background-image: -webkit-linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1)
  ); /* For Chrome 25 and Safari 6, iOS 6.1, Android 4.3 */
  background-image: -moz-linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1)
  ); /* For Firefox (3.6 to 15) */
  background-image: -o-linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1)
  ); /* For old Opera (11.1 to 12.0) */
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1)
  ); /* Standard syntax; must be last */
}

.slider-overlay {
  background-image: -webkit-linear-gradient(
    to right,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  ); /* For Chrome 25 and Safari 6, iOS 6.1, Android 4.3 */
  background-image: -moz-linear-gradient(
    to right,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  ); /* For Firefox (3.6 to 15) */
  background-image: -o-linear-gradient(
    to right,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  ); /* For old Opera (11.1 to 12.0) */
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  ); /* Standard syntax; must be last */
}

.slider-overlay-rev {
  background-image: -webkit-linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 1)
  ); /* For Chrome 25 and Safari 6, iOS 6.1, Android 4.3 */
  background-image: -moz-linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 1)
  ); /* For Firefox (3.6 to 15) */
  background-image: -o-linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 1)
  ); /* For old Opera (11.1 to 12.0) */
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 1)
  ); /* Standard syntax; must be last */
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

@keyframes typing {
  0%,
  100% {
    transform: translateY(0);
    opacity: 0.5;
  }
  50% {
    transform: translateY(-2px);
    opacity: 1;
  }
}

@keyframes loading-dots {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes wave {
  0%,
  100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.6);
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes text-blink {
  0%,
  100% {
    color: var(--primary);
  }
  50% {
    color: var(--muted-foreground);
  }
}

@keyframes bounce-dots {
  0%,
  100% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
}

@keyframes thin-pulse {
  0%,
  100% {
    transform: scale(0.95);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.4;
  }
}

@keyframes pulse-dot {
  0%,
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
}

@keyframes shimmer-text {
  0% {
    background-position: 150% center;
  }
  100% {
    background-position: -150% center;
  }
}

@keyframes wave-bars {
  0%,
  100% {
    transform: scaleY(1);
    opacity: 0.5;
  }
  50% {
    transform: scaleY(0.6);
    opacity: 1;
  }
}

@keyframes shimmer {
  0% {
    background-position: 200% 50%;
  }
  100% {
    background-position: -200% 50%;
  }
}

@keyframes spinner-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
